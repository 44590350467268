import { Mark, mergeAttributes } from '@tiptap/core';
export var CommentExtension = Mark.create({
  name: 'comment',
  addOptions: function addOptions() {
    return {
      HTMLAttributes: {},
      onCommentActivated: function onCommentActivated() {}
    };
  },
  addAttributes: function addAttributes() {
    return {
      commentId: {
        "default": null,
        parseHTML: function parseHTML(el) {
          return el.getAttribute('data-comment-id');
        },
        renderHTML: function renderHTML(attrs) {
          return {
            'data-comment-id': attrs.commentId
          };
        }
      }
    };
  },
  parseHTML: function parseHTML() {
    return [{
      tag: 'span[data-comment-id]',
      getAttrs: function getAttrs(el) {
        var _getAttribute;

        return !!((_getAttribute = el.getAttribute('data-comment-id')) !== null && _getAttribute !== void 0 && _getAttribute.trim()) && null;
      }
    }];
  },
  renderHTML: function renderHTML(_ref) {
    var HTMLAttributes = _ref.HTMLAttributes;
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  onSelectionUpdate: function onSelectionUpdate() {
    var $from = this.editor.state.selection.$from;
    var marks = $from.marks();

    if (!marks.length) {
      this.storage.activeCommentId = null;
      this.options.onCommentActivated(this.storage.activeCommentId);
      return;
    }

    var commentMark = this.editor.schema.marks.comment;
    var activeCommentMark = marks.find(function (mark) {
      return mark.type === commentMark;
    });
    this.storage.activeCommentId = (activeCommentMark === null || activeCommentMark === void 0 ? void 0 : activeCommentMark.attrs.commentId) || null;
    this.options.onCommentActivated(this.storage.activeCommentId);
  },
  addStorage: function addStorage() {
    return {
      activeCommentId: null
    };
  },
  addCommands: function addCommands() {
    return {
      setComment: function setComment(commentId) {
        return function (_ref2) {
          var commands = _ref2.commands;
          if (!commentId) return false;
          commands.setMark('comment', {
            commentId: commentId
          });
        };
      },
      unsetComment: function unsetComment(commentId) {
        return function (_ref3) {
          var tr = _ref3.tr,
              dispatch = _ref3.dispatch;
          if (!commentId) return false;
          var commentMarksWithRange = [];
          tr.doc.descendants(function (node, pos) {
            var commentMark = node.marks.find(function (mark) {
              return mark.type.name === 'comment' && mark.attrs.commentId === commentId;
            });
            if (!commentMark) return;
            commentMarksWithRange.push({
              mark: commentMark,
              range: {
                from: pos,
                to: pos + node.nodeSize
              }
            });
          });
          commentMarksWithRange.forEach(function (_ref4) {
            var mark = _ref4.mark,
                range = _ref4.range;
            tr.removeMark(range.from, range.to, mark);
          });
          return dispatch === null || dispatch === void 0 ? void 0 : dispatch(tr);
        };
      }
    };
  }
});
export var getNewComment = function getNewComment(content) {
  return {
    id: "a".concat(crypto.randomUUID(), "a"),
    content: content,
    replies: [],
    createdAt: new Date()
  };
};