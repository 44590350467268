import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { reset as _reset } from './action';
export var getUseCase = function getUseCase(useCaseName, dispatch, translation) {
  return {
    name: useCaseName,
    execute: function execute(input) {
      var action = input ? {
        type: useCaseName,
        payload: input,
        translation: translation
      } : {
        type: useCaseName,
        translation: translation
      };
      if (!action.type) return;
      dispatch(action);
    },
    reset: function reset(input) {
      var action = {
        type: "".concat(useCaseName, "_").concat(_reset),
        payload: input
      };
      dispatch(action);
    }
  };
};
export var useUseCase = function useUseCase(useCaseName, input) {
  var dispatch = useDispatch();
  useEffect(function () {
    var action = input ? {
      type: useCaseName,
      payload: input
    } : {
      type: useCaseName
    };
    dispatch(action);
  }, []);
};