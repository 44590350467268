import {
  ConfirmationResponse,
  ResetPasswordResponse,
  SignInResponse,
  VerifyAccessTokenOutput,
} from '@module/user';
import { UseCaseError } from '@digibricks/domains';

export enum RequestState {
  REQUEST = 0,
  SUCCESS = 1,
  FAIL = 2,
}
export type AuthInitialState = {
  error?: UseCaseError<any>;
  payload: any;
};
export type SignInUseCaseState = {
  isLoadingSignIn: boolean;
  signInResponse: SignInResponse;
  isSignInSuccess: boolean;
};

export type SignUpUseCaseState = {
  isLoadingSignUp: boolean;
  isSignUpSuccess: boolean;
  isInvalidPasswordError: boolean;
};
export type ForgotPasswordUseCaseState = {
  isLoadingSendForgotPasswordEmail: boolean;
  isForgotPasswordEmailSuccess: boolean;
  sendEmailForForgotPasswordStatus: RequestState;
};
export type ConfirmationUseCaseState = {
  confirmationStatus: RequestState;
  confirmationResponse: ConfirmationResponse;
};
export type ResetPasswordUseCaseState = {
  isLoadingResetPassword: boolean;
  resetPasswordStatus: RequestState;
  resetPasswordResponse: ResetPasswordResponse;
};
export type SignOutForIdentityUseCaseType = {
  signOutStatus: RequestState;
};

export type VerifyAccessTokenState = {
  verifyAccessTokenResponse: VerifyAccessTokenOutput;
};
export type AuthState = AuthInitialState &
  SignInUseCaseState &
  SignUpUseCaseState &
  ForgotPasswordUseCaseState &
  ConfirmationUseCaseState &
  ResetPasswordUseCaseState &
  SignOutForIdentityUseCaseType &
  VerifyAccessTokenState;

export const initialState: AuthInitialState = {
  error: null,
  payload: null,
};
