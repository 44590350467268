import { RouterPath } from '../../router-path';
export var testMakerAppPath = {
  space: RouterPath.create('/space'),
  callback: RouterPath.create('/callback'),
  form: {
    detail: function detail(id) {
      return RouterPath.create("form/".concat(id));
    },
    preview: function preview(id) {
      return RouterPath.create("form/".concat(id));
    },
    edit: function edit(id) {
      return RouterPath.create("form/".concat(id));
    }
  }
};