import { createReducer } from '@lib/plugin-redux-core';
import { UPDATE_PROFILE_USE_CASE, SIGN_IN_BY_GOOGLE_USE_CASE } from '@module/user';
import {
  handleSignInByGoogleUseCase,
  handleUpdateUserInformationUseCase,
} from './google-callback.handler';
import { initialState } from './google-callback.state';

export const googleCallbackPageReducer = createReducer(initialState, [
  {
    name: UPDATE_PROFILE_USE_CASE,
    ...handleUpdateUserInformationUseCase,
  },
  {
    name: SIGN_IN_BY_GOOGLE_USE_CASE,
    ...handleSignInByGoogleUseCase,
  },
]);
