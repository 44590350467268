import { createReducer } from '@lib/plugin-redux-core';
import {
  SIGN_IN_USE_CASE,
  SIGN_UP_USE_CASE,
  CONFIRMATION_USE_CASE,
  FORGOT_PASSWORD_USE_CASE,
  RESET_PASSWORD_USE_CASE,
} from '../root.action';
import {
  handleConfirmationUseCase,
  handleForgotPasswordUseCase,
  handleResetPasswordUseCase,
  handleSignInUseCase,
  handleSignUpUseCase,
  handleSignOutForIdentityUseCase,
  handleVerifyAccessTokenState,
} from './auth.handler';
import { clearStateReducer } from '@lib/common';
import { initialState } from './auth.state';
import { SIGN_OUT_FOR_IDENTITY_USE_CASE, VERIFY_ACCESS_TOKEN_USE_CASE } from '@module/user';

export const authReducer = createReducer(initialState, [
  clearStateReducer(initialState),
  {
    name: SIGN_IN_USE_CASE,
    ...handleSignInUseCase,
  },
  {
    name: SIGN_UP_USE_CASE,
    ...handleSignUpUseCase,
  },
  {
    name: CONFIRMATION_USE_CASE,
    ...handleConfirmationUseCase,
  },
  {
    name: FORGOT_PASSWORD_USE_CASE,
    ...handleForgotPasswordUseCase,
  },
  {
    name: RESET_PASSWORD_USE_CASE,
    ...handleResetPasswordUseCase,
  },
  {
    name: SIGN_OUT_FOR_IDENTITY_USE_CASE,
    ...handleSignOutForIdentityUseCase,
  },
  {
    name: VERIFY_ACCESS_TOKEN_USE_CASE,
    ...handleVerifyAccessTokenState,
  },
]);
