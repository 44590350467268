import { ConfirmationResponse, ResetPasswordResponse, VerifyAccessTokenOutput } from '@module/user';
import { Action } from '@lib/plugin-redux-core';

import { AuthState, RequestState } from './auth.state';

export const handleSignInUseCase = {
  executing: (state: AuthState, action: Action): AuthState => {
    const { payload } = action;
    return { ...state, payload, isLoadingSignIn: true };
  },
  success: (state: AuthState, action: Action): AuthState => {
    const { payload } = action;
    return {
      ...state,
      signInResponse: payload,
      isLoadingSignIn: false,
    };
  },
  error: (state: AuthState, action: Action): AuthState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      isLoadingSignIn: false,
    };
  },
};

export const handleSignUpUseCase = {
  executing: (state: AuthState, action: Action): AuthState => {
    const { payload } = action;
    return { ...state, payload, isLoadingSignUp: true };
  },

  success: (state: AuthState): AuthState => {
    return {
      ...state,
      isSignUpSuccess: true,
      isLoadingSignUp: false,
      isInvalidPasswordError: false,
    };
  },
  error: (state: AuthState, action: Action): AuthState => {
    const { error } = action;
    const errorMessage = (error.error as Error).message;

    return {
      ...state,
      error: error,
      isSignUpSuccess: false,
      isLoadingSignUp: false,
      isInvalidPasswordError: errorMessage === 'InvalidPasswordError',
    };
  },
};
export const handleConfirmationUseCase = {
  executing: (state: AuthState, action: Action): AuthState => {
    const { payload } = action;
    return { ...state, payload, confirmationStatus: RequestState.REQUEST };
  },
  success: (state: AuthState, action: Action): AuthState => {
    const { payload } = action;
    return {
      ...state,
      confirmationResponse: payload as ConfirmationResponse,
      confirmationStatus: RequestState.SUCCESS,
    };
  },
  error: (state: AuthState, action: Action): AuthState => {
    const { error } = action;
    return { ...state, error: error, confirmationStatus: RequestState.FAIL };
  },
  reset: (state: AuthState): AuthState => {
    return { ...state, confirmationStatus: RequestState.REQUEST };
  },
};
export const handleForgotPasswordUseCase = {
  executing: (state: AuthState, action: Action): AuthState => {
    const { payload } = action;

    return {
      ...state,
      payload,
      isLoadingSendForgotPasswordEmail: true,
      sendEmailForForgotPasswordStatus: RequestState.REQUEST,
    };
  },
  success: (state: AuthState): AuthState => {
    return {
      ...state,
      isLoadingSendForgotPasswordEmail: false,
      isForgotPasswordEmailSuccess: true,
      sendEmailForForgotPasswordStatus: RequestState.SUCCESS,
    };
  },
  error: (state: AuthState, action: Action): AuthState => {
    const { error } = action;

    return {
      ...state,
      isLoadingSendForgotPasswordEmail: false,
      isForgotPasswordEmailSuccess: false,
      sendEmailForForgotPasswordStatus: RequestState.FAIL,
      error: error,
    };
  },
  reset: (state: AuthState): AuthState => {
    return {
      ...state,
      isForgotPasswordEmailSuccess: false,
      sendEmailForForgotPasswordStatus: RequestState.REQUEST,
    };
  },
};
export const handleResetPasswordUseCase = {
  executing: (state: AuthState, action: Action): AuthState => {
    const { payload } = action;

    return {
      ...state,
      payload,
      isLoadingResetPassword: true,
      resetPasswordStatus: RequestState.REQUEST,
    };
  },
  success: (state: AuthState, action: Action): AuthState => {
    const { payload } = action;

    return {
      ...state,
      isLoadingResetPassword: false,
      resetPasswordStatus: RequestState.SUCCESS,
      resetPasswordResponse: payload as ResetPasswordResponse,
    };
  },
  error: (state: AuthState, action: Action): AuthState => {
    const { error } = action;

    return {
      ...state,
      isLoadingResetPassword: false,
      resetPasswordStatus: RequestState.FAIL,
      error: error,
    };
  },
  reset: (state: AuthState): AuthState => {
    return {
      ...state,
      resetPasswordStatus: RequestState.REQUEST,
    };
  },
};
export const handleSignOutForIdentityUseCase = {
  executing: (state: AuthState): AuthState => {
    return {
      ...state,
      signOutStatus: RequestState.REQUEST,
    };
  },
  success: (state: AuthState): AuthState => {
    return {
      ...state,
      signOutStatus: RequestState.SUCCESS,
    };
  },
  error: (state: AuthState, action: Action): AuthState => {
    const { error } = action;
    return {
      ...state,
      signOutStatus: RequestState.FAIL,
      error,
    };
  },
};

export const handleVerifyAccessTokenState = {
  executing: (state: AuthState, action: Action): AuthState => {
    const { payload } = action;

    return {
      ...state,
      payload,
      verifyAccessTokenResponse: null,
    };
  },
  success: (state: AuthState, action: Action): AuthState => {
    const { payload } = action;
    return {
      ...state,
      verifyAccessTokenResponse: payload as VerifyAccessTokenOutput,
    };
  },
  error: (state: AuthState, action: Action): AuthState => {
    const { error } = action;
    return {
      ...state,
      signOutStatus: RequestState.FAIL,
      error,
    };
  },
};
