import { combineReducers } from 'redux';
import { AccountSettingPageState, accountSettingReducer } from './account-setting';
import { AuthState, authReducer } from './auth';
import { commonReducer } from './common';
import { GoogleCallbackPageState, googleCallbackPageReducer } from './google-callback';

export type RootReducerState = {
  auth: AuthState;
  accountSetting: AccountSettingPageState;
  common: any;
  googleCallbackPage: GoogleCallbackPageState;
};

export const rootReducer = combineReducers({
  auth: authReducer,
  googleCallbackPage: googleCallbackPageReducer,
  accountSetting: accountSettingReducer,
  common: commonReducer,
});
