import { useState, useEffect } from 'react';
import { css } from 'styled-components';
export var DeviceType;

(function (DeviceType) {
  DeviceType[DeviceType["mobile"] = 0] = "mobile";
  DeviceType[DeviceType["desktop"] = 1] = "desktop";
})(DeviceType || (DeviceType = {}));

export var useResponsive = function useResponsive(_ref) {
  var mobile = _ref.mobile,
      desktop = _ref.desktop;

  var _useState = useState({
    width: undefined,
    height: undefined
  }),
      _ = _useState[0],
      setWindowSize = _useState[1];

  var _useState2 = useState(false),
      isMobile = _useState2[0],
      setIsMobile = _useState2[1];

  var minWidths = {
    mobile: 0,
    desktop: mobile + 1
  };
  var maxWidths = {
    mobile: mobile,
    desktop: desktop
  };
  useEffect(function () {
    var handleResize = function handleResize() {
      setIsMobile(window.innerWidth > mobile);
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return function () {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  var getMediaQuery = function getMediaQuery(minWidth, maxWidth) {
    return function (args) {
      return css(["@media (min-width:", "px) and (max-width:", "px){", ";}"], minWidth, maxWidth, css(args));
    };
  };

  var media = {
    mobile: getMediaQuery(minWidths.mobile, maxWidths.mobile),
    desktop: getMediaQuery(minWidths.desktop, maxWidths.desktop),
    isMobile: isMobile ? DeviceType.mobile : DeviceType.desktop
  };
  return media;
};