import { Module } from '@nestjs/common';
import { authUseCaseProviders } from './usecase-providers/auth.provider';
import { authApiProvider } from './api-providers';
import { authenticationRepositoryProvider } from '@module/user';

@Module({
  providers: [authApiProvider, authenticationRepositoryProvider, ...authUseCaseProviders],
  exports: [...authUseCaseProviders],
})
export class AuthModule {}
