import { RouterPath } from '../../router-path';
export var identityAppPath = {
  authentication: RouterPath.create('/authentication'),
  forgotPassword: RouterPath.create('/forgot-password'),
  validateEmailConfirmation: RouterPath.create('/validate-email-confirmation'),
  notFoundEmail: RouterPath.create('/not-found-email'),
  resetPassword: RouterPath.create('/reset-password'),
  requestEmail: RouterPath.create('/request-email'),
  signOut: RouterPath.create('/logout'),
  updateProfile: RouterPath.create('/account-setting/update-profile'),
  changePassword: RouterPath.create('/account-setting/change-password')
};