import _defineProperty from "/vercel/path0/node_modules/.pnpm/@babel+runtime@7.15.4/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "/vercel/path0/node_modules/.pnpm/@babel+runtime@7.15.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["title", "icon", "content", "okText", "cancelText", "onCancel", "onOk", "className"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { StyledModal } from './modal.component';
export var showModalConfirm = function showModalConfirm(_ref) {
  var title = _ref.title,
      icon = _ref.icon,
      _ref$content = _ref.content,
      content = _ref$content === void 0 ? 'Bạn có muốn tiếp thực hiện thao tác này?' : _ref$content,
      okText = _ref.okText,
      cancelText = _ref.cancelText,
      onCancel = _ref.onCancel,
      onOk = _ref.onOk,
      _ref$className = _ref.className,
      className = _ref$className === void 0 ? 'modal-confirm' : _ref$className,
      props = _objectWithoutProperties(_ref, _excluded);

  StyledModal.confirm(_objectSpread({
    title: title,
    icon: icon,
    content: content,
    okText: okText,
    cancelText: cancelText,
    onOk: onOk,
    onCancel: onCancel,
    className: className
  }, props));
};