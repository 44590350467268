import { RouterPath } from '../../router-path';
export var responderAppPath = {
  dashboard: RouterPath.create('/participated-assignment'),
  callback: RouterPath.create('/callback'),
  assignment: {
    detail: function detail(id) {
      return RouterPath.create("assignment/".concat(id));
    }
  },
  submission: {
    detail: function detail(id) {
      return RouterPath.create("submission/".concat(id));
    },
    practicePreview: function practicePreview(id) {
      return RouterPath.create("submission/preview/".concat(id));
    },
    homeworkPreview: function homeworkPreview(id) {
      return RouterPath.create("submission/preview/homework/".concat(id));
    },
    examinationPreview: function examinationPreview(id) {
      return RouterPath.create("submission/preview/examination/".concat(id));
    },
    submissionSubmit: function submissionSubmit(id) {
      return RouterPath.create("submission/submit/".concat(id));
    }
  }
};