import { Action } from '@lib/plugin-redux-core';
import { UpdateUserProfileUseCaseInput, User } from '@module/user';
import { AccountSettingPageState, AccountSettingStatus } from './account-setting.state';
import { CreatedFileStorage } from '@module/file-storage';

const updateUserInfo = (userResponse: User, input: UpdateUserProfileUseCaseInput): User => {
  if (!userResponse) {
    return null;
  }
  const { phoneNumber, firstName, lastName, username } = input.input;
  userResponse.updateFirstName(firstName);
  userResponse.updateLastName(lastName);
  userResponse.updatePhoneNumber(phoneNumber);
  userResponse.updateUsername(username);

  return userResponse;
};

export const handleGetUserByIdUseCase = {
  executing: (state: AccountSettingPageState): AccountSettingPageState => {
    return {
      ...state,
      getUserInformationStatus: AccountSettingStatus.EXECUTING,
    };
  },
  success: (state: AccountSettingPageState, action: Action): AccountSettingPageState => {
    const { payload } = action;

    return {
      ...state,
      userResponse: payload as User,
      getUserInformationStatus: AccountSettingStatus.SUCCESS,
    };
  },
  error: (state: AccountSettingPageState, action: Action): AccountSettingPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      getUserInformationStatus: AccountSettingStatus.ERROR,
    };
  },
};

export const handleUpdateProfileUseCase = {
  executing: (state: AccountSettingPageState, action: Action): AccountSettingPageState => {
    const { payload } = action;

    return {
      ...state,
      updateProfileStatus: AccountSettingStatus.EXECUTING,
      updateProfileInput: payload as UpdateUserProfileUseCaseInput,
    };
  },
  success: (state: AccountSettingPageState): AccountSettingPageState => {
    //TODO: Need to fix this case
    const userResponse = updateUserInfo(state.userResponse, state.updateProfileInput);
    return {
      ...state,
      userResponse,
      updateProfileStatus: AccountSettingStatus.SUCCESS,
    };
  },
  error: (state: AccountSettingPageState, action: Action): AccountSettingPageState => {
    const { error } = action;

    return {
      ...state,
      error: error,
      updateProfileStatus: AccountSettingStatus.ERROR,
    };
  },
};

export const handleChangePasswordUseCase = {
  executing: (state: AccountSettingPageState): AccountSettingPageState => {
    return {
      ...state,
      updatePasswordStatus: AccountSettingStatus.EXECUTING,
    };
  },
  success: (state: AccountSettingPageState): AccountSettingPageState => {
    return {
      ...state,
      updatePasswordStatus: AccountSettingStatus.SUCCESS,
    };
  },
  error: (state: AccountSettingPageState, action: Action): AccountSettingPageState => {
    const { error } = action;

    return {
      ...state,
      error: error,
      updatePasswordStatus: AccountSettingStatus.ERROR,
    };
  },
  reset: (state: AccountSettingPageState): AccountSettingPageState => {
    return {
      ...state,
      updatePasswordStatus: AccountSettingStatus.RESET,
    };
  },
};

export const handleCreateProfileFileStorageUseCase = {
  executing: (state: AccountSettingPageState): AccountSettingPageState => {
    return {
      ...state,
      createProfileFileStatus: AccountSettingStatus.EXECUTING,
    };
  },
  success: (state: AccountSettingPageState, action: Action): AccountSettingPageState => {
    return {
      ...state,
      createProfileFileResponse: action.payload as CreatedFileStorage,
      createProfileFileStatus: AccountSettingStatus.SUCCESS,
    };
  },
  error: (state: AccountSettingPageState, action: Action): AccountSettingPageState => {
    const { error } = action;

    return {
      ...state,
      error: error,
      createProfileFileStatus: AccountSettingStatus.ERROR,
    };
  },
};
