import {
  CREATE_PROFILE_FILE_STORAGE_USE_CASE,
  CreateProfileFileUseCase,
  EXTERNAL_FILE_STORAGE_REPOSITORY,
  FILE_STORAGE_REPOSITORY,
  IExternalFileStorageRepository,
  IFileStorageRepository,
} from '@module/file-storage';

export const fileStorageUseCaseProviders = [
  {
    provide: CREATE_PROFILE_FILE_STORAGE_USE_CASE,
    useFactory: (
      fileStorageRepository: IFileStorageRepository,
      externalFileStorageRepository: IExternalFileStorageRepository,
    ) => {
      return new CreateProfileFileUseCase(fileStorageRepository, externalFileStorageRepository);
    },
    inject: [FILE_STORAGE_REPOSITORY, EXTERNAL_FILE_STORAGE_REPOSITORY],
  },
];
