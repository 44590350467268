import { useState, useEffect } from 'react';
var DeviceType;

(function (DeviceType) {
  DeviceType[DeviceType["mobile"] = 0] = "mobile";
  DeviceType[DeviceType["desktop"] = 1] = "desktop";
})(DeviceType || (DeviceType = {}));

export var useDeviceType = function useDeviceType() {
  var _useState = useState(false),
      isMobile = _useState[0],
      setIsMobile = _useState[1];

  useEffect(function () {
    var handleResize = function handleResize() {
      setIsMobile(window.innerWidth <= 990);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return function () {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return isMobile ? DeviceType.mobile : DeviceType.desktop;
};