import {
  TestMakerAppRouter,
  ResponderAppRouter,
  IdentityAppRouter,
  routeManager,
} from '@lib/route-manager';

export const loadAppRouters = () => {
  const environment = process.env.ENVIRONMENT;
  const branchName = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF;

  routeManager.registerStandardAppRouters({
    testMaker: TestMakerAppRouter.register({
      branchName,
      environment,
      baseUrl: process.env.TEACHER_APP_URL_PATH,
    }),
    responder: ResponderAppRouter.register({
      branchName,
      environment,
      baseUrl: process.env.STUDENT_APP_URL_PATH,
    }),
    identity: IdentityAppRouter.register({
      branchName,
      environment,
      baseUrl: process.env.APP_BASE_URL,
    }),
  });
};
