import {
  EXTERNAL_FILE_STORAGE_API,
  ExternalFileStorageApi,
  FILE_STORAGE_API,
  FileStorageApi,
} from '@module/file-storage';
import { AuthApi, AUTH_API, UserApi, USER_API } from '@module/user';
import { AxiosInstanceBuilder } from '@digibricks/plugin-axios';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const timeout = publicRuntimeConfig.api.timeout;

export const authApiProvider = {
  provide: AUTH_API,
  useFactory: () => {
    return new AuthApi(
      new AxiosInstanceBuilder()
        .withBaseUrl(publicRuntimeConfig.api.baseUrl)
        .withTimeout(timeout)
        .toInstance(),
    );
  },
};

export const apiProviders = [
  authApiProvider,
  {
    provide: USER_API,
    useFactory: () => {
      return new UserApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: FILE_STORAGE_API,
    useFactory: () => {
      return new FileStorageApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFileStorageUrl)
          .withTimeout(3000)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: EXTERNAL_FILE_STORAGE_API,
    useFactory: () => {
      return new ExternalFileStorageApi(new AxiosInstanceBuilder().withTimeout(0).toInstance());
    },
  },
];
