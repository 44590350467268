import {
  ConfirmationResponse,
  ResetPasswordResponse,
  SignInResponse,
  VerifyAccessTokenOutput,
} from '@module/user';
import { RequestState, VerifyAccessTokenState } from './auth.state';
import { RootReducerState } from 'redux/root.reducer';
import { UseCaseError } from '@digibricks/domains';

// Common
export const errorMessageSelector = (state: RootReducerState): UseCaseError<string> =>
  state.auth.error;

// Sign In
export const loadingSignInSelector = (state: RootReducerState): boolean =>
  state.auth.isLoadingSignIn;
export const signInResponseSelector = (state: RootReducerState): SignInResponse =>
  state.auth.signInResponse;

// Sign Up
export const loadingSignUpSelector = (state: RootReducerState): boolean =>
  state.auth.isLoadingSignUp;
export const signUpStatusSelector = (state: RootReducerState): boolean =>
  state.auth.isSignUpSuccess;

// Forgot Password
export const loadingSendForgotPasswordEmailSelector = (state: RootReducerState): boolean =>
  state.auth.isLoadingSendForgotPasswordEmail;
export const sendForgotPasswordStatusSelector = (state: RootReducerState): boolean =>
  state.auth.isForgotPasswordEmailSuccess;
export const sendEmailForForgotPasswordStatusSelector = (state: RootReducerState): number =>
  state.auth.sendEmailForForgotPasswordStatus;
// Confirmation
export const confirmationResponseSelector = (state: RootReducerState): ConfirmationResponse =>
  state.auth.confirmationResponse;
export const confirmationStatusSelector = (state: RootReducerState): RequestState =>
  state.auth.confirmationStatus;

// Reset Password
export const loadingResetPasswordSelector = (state: RootReducerState): boolean =>
  state.auth.isLoadingResetPassword;
export const resetPasswordStatusSelector = (state: RootReducerState): RequestState =>
  state.auth.resetPasswordStatus;
export const resetPasswordResponseSelector = (state: RootReducerState): ResetPasswordResponse =>
  state.auth.resetPasswordResponse;
// Sign out
export const signOutStatusSelector = (state: RootReducerState): number => state.auth.signOutStatus;

//Verify AccessToken
export const verifyAccessTokenSelector = (state: RootReducerState): VerifyAccessTokenOutput =>
  state.auth.verifyAccessTokenResponse;
