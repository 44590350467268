import { User } from '@module/user';
import { RootReducerState } from 'redux/root.reducer';
import { AccountSettingStatus } from '.';

// Common
export const userResponseSelector = (state: RootReducerState): User =>
  state.accountSetting.userResponse;

export const getUserInformationStatusSelector = (state: RootReducerState): AccountSettingStatus =>
  state.accountSetting.getUserInformationStatus;

// Update Profile
export const updateProfileStatusSelector = (state: RootReducerState): AccountSettingStatus =>
  state.accountSetting.updateProfileStatus;

// Change Password
export const changePasswordStatusSelector = (state: RootReducerState): AccountSettingStatus =>
  state.accountSetting.updatePasswordStatus;
