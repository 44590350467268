import _slicedToArray from "/vercel/path0/node_modules/.pnpm/@babel+runtime@7.15.4/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "/vercel/path0/node_modules/.pnpm/@babel+runtime@7.15.4/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import Image from '@tiptap/extension-image';
export var ImageResize = Image.extend({
  addAttributes: function addAttributes() {
    return {
      src: {
        "default": null
      },
      alt: {
        "default": null
      },
      style: {
        "default": 'width: 100%; height: auto; cursor: pointer;',
        parseHTML: function parseHTML(element) {
          var width = element.getAttribute('width');
          return width ? "width: ".concat(width, "px; height: auto; cursor: pointer;") : "".concat(element.style.cssText);
        }
      },
      title: {
        "default": null
      },
      loading: {
        "default": null
      },
      srcset: {
        "default": null
      },
      sizes: {
        "default": null
      },
      crossorigin: {
        "default": null
      },
      usemap: {
        "default": null
      },
      ismap: {
        "default": null
      },
      width: {
        "default": null
      },
      height: {
        "default": null
      },
      referrerpolicy: {
        "default": null
      },
      longdesc: {
        "default": null
      },
      decoding: {
        "default": null
      },
      "class": {
        "default": null
      },
      id: {
        "default": null
      },
      name: {
        "default": null
      },
      draggable: {
        "default": true
      },
      tabindex: {
        "default": null
      },
      'aria-label': {
        "default": null
      },
      'aria-labelledby': {
        "default": null
      },
      'aria-describedby': {
        "default": null
      }
    };
  },
  addNodeView: function addNodeView() {
    return function (_ref) {
      var node = _ref.node,
          editor = _ref.editor,
          getPos = _ref.getPos;
      var view = editor.view,
          editable = editor.options.editable;
      var style = node.attrs.style;
      var $wrapper = document.createElement('span');
      var $container = document.createElement('span');
      var $img = document.createElement('img');
      var iconStyle = 'width: 24px; height: 24px; cursor: pointer;';

      var dispatchNodeView = function dispatchNodeView() {
        if (typeof getPos === 'function') {
          var newAttrs = _objectSpread(_objectSpread({}, node.attrs), {}, {
            style: "".concat($img.style.cssText)
          });

          view.dispatch(view.state.tr.setNodeMarkup(getPos(), null, newAttrs));
        }
      };

      var paintPositionContoller = function paintPositionContoller() {
        var $postionController = document.createElement('span');
        var $leftController = document.createElement('img');
        var $centerController = document.createElement('img');
        var $rightController = document.createElement('img');

        var controllerMouseOver = function controllerMouseOver(e) {
          e.target.style.opacity = 0.3;
        };

        var controllerMouseOut = function controllerMouseOut(e) {
          e.target.style.opacity = 1;
        };

        $postionController.setAttribute('style', 'position: absolute; top: 0%; left: 50%; width: 100px; height: 25px; z-index: 999; background-color: rgba(255, 255, 255, 0.7); border-radius: 4px; border: 2px solid #6C6C6C; cursor: pointer; transform: translate(-50%, -50%); display: flex; justify-content: space-between; align-items: center; padding: 0 10px;');
        $leftController.setAttribute('src', 'https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/format_align_left/default/20px.svg');
        $leftController.setAttribute('style', iconStyle);
        $leftController.addEventListener('mouseover', controllerMouseOver);
        $leftController.addEventListener('mouseout', controllerMouseOut);
        $centerController.setAttribute('src', 'https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/format_align_center/default/20px.svg');
        $centerController.setAttribute('style', iconStyle);
        $centerController.addEventListener('mouseover', controllerMouseOver);
        $centerController.addEventListener('mouseout', controllerMouseOut);
        $rightController.setAttribute('src', 'https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/format_align_right/default/20px.svg');
        $rightController.setAttribute('style', iconStyle);
        $rightController.addEventListener('mouseover', controllerMouseOver);
        $rightController.addEventListener('mouseout', controllerMouseOut);
        $leftController.addEventListener('click', function () {
          $img.setAttribute('style', "".concat($img.style.cssText, " margin: 0 auto 0 0;"));
          dispatchNodeView();
        });
        $centerController.addEventListener('click', function () {
          $img.setAttribute('style', "".concat($img.style.cssText, " margin: 0 auto;"));
          dispatchNodeView();
        });
        $rightController.addEventListener('click', function () {
          $img.setAttribute('style', "".concat($img.style.cssText, " margin: 0 0 0 auto;"));
          dispatchNodeView();
        });
        $postionController.appendChild($leftController);
        $postionController.appendChild($centerController);
        $postionController.appendChild($rightController);
        $container.appendChild($postionController);
      };

      $wrapper.setAttribute('style', "display: flex;");
      $wrapper.appendChild($container);
      $container.setAttribute('style', "".concat(style));
      $container.appendChild($img);
      Object.entries(node.attrs).forEach(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 2),
            key = _ref3[0],
            value = _ref3[1];

        if (value === undefined || value === null) return;
        $img.setAttribute(key, value);
      });
      if (!editable) return {
        dom: $img
      };
      var dotsPosition = ['top: -4px; left: -4px; cursor: nwse-resize;', 'top: -4px; right: -4px; cursor: nesw-resize;', 'bottom: -4px; left: -4px; cursor: nesw-resize;', 'bottom: -4px; right: -4px; cursor: nwse-resize;'];
      var isResizing = false;
      var startX, startWidth;
      $container.addEventListener('click', function () {
        //remove remaining dots and position controller
        if ($container.childElementCount > 3) {
          for (var i = 0; i < 5; i++) {
            $container.removeChild($container.lastChild);
          }
        }

        paintPositionContoller();
        $container.setAttribute('style', "position: relative; border: 1px dashed #6C6C6C; ".concat(style, " cursor: pointer;"));
        Array.from({
          length: 4
        }, function (_, index) {
          var $dot = document.createElement('div');
          $dot.setAttribute('style', "position: absolute; width: 9px; height: 9px; border: 1.5px solid #6C6C6C; border-radius: 50%; ".concat(dotsPosition[index]));
          $dot.addEventListener('mousedown', function (e) {
            e.preventDefault();
            isResizing = true;
            startX = e.clientX;
            startWidth = $container.offsetWidth;

            var onMouseMove = function onMouseMove(e) {
              if (!isResizing) return;
              var deltaX = index % 2 === 0 ? -(e.clientX - startX) : e.clientX - startX;
              var newWidth = startWidth + deltaX;
              $container.style.width = newWidth + 'px';
              $img.style.width = newWidth + 'px';
            };

            var onMouseUp = function onMouseUp() {
              if (isResizing) {
                isResizing = false;
              }

              dispatchNodeView();
              document.removeEventListener('mousemove', onMouseMove);
              document.removeEventListener('mouseup', onMouseUp);
            };

            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
          });
          $container.appendChild($dot);
        });
      });
      document.addEventListener('click', function (e) {
        var $target = e.target;
        var isClickInside = $container.contains($target) || $target.style.cssText === iconStyle;

        if (!isClickInside) {
          var containerStyle = $container.getAttribute('style');
          var newStyle = containerStyle === null || containerStyle === void 0 ? void 0 : containerStyle.replace('border: 1px dashed #6C6C6C;', '');
          $container.setAttribute('style', newStyle);

          if ($container.childElementCount > 3) {
            for (var i = 0; i < 5; i++) {
              $container.removeChild($container.lastChild);
            }
          }
        }
      });
      return {
        dom: $wrapper
      };
    };
  }
});