import { notification } from 'antd';
export var showToastHandler = function showToastHandler(action) {
  var error = action.error,
      translation = action.translation;
  if (!error) return;
  var parseError = typeof error.error == 'string' ? error.error : error.error.message;
  var message = translation ? translation(parseError) : parseError;
  notification['error']({
    message: translation ? translation('error') : 'error',
    description: message,
    duration: 5
  });
};