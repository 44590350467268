export var AppId;

(function (AppId) {
  AppId["Responder"] = "responder";
  AppId["TestMaker"] = "test-maker";
  AppId["Identity"] = "identity";
})(AppId || (AppId = {}));

export var routerConfig = {
  appId: AppId
};