import { RootReducerState } from 'redux/root.reducer';
import { SignInResponse } from '@module/user';
import { GoogleCallbackPageStatus } from './google-callback.state';

export const updateUserInformationStatusSelector = (
  state: RootReducerState,
): GoogleCallbackPageStatus => state.googleCallbackPage.updateUserInformationStatus;
export const signInByGoogleResponseSelector = (state: RootReducerState): SignInResponse =>
  state.googleCallbackPage.signInByGoogleResponse;
export const loadingSignInGoogleSelector = (state: RootReducerState): boolean =>
  state.googleCallbackPage.isLoadingSignInByGoogle;
export const signInStatusSelector = (state: RootReducerState): boolean =>
  state.auth.isSignInSuccess;
export const signInGoogleSuccessStatusSelector = (state: RootReducerState): boolean =>
  state.googleCallbackPage.isSignInByGoogleSuccess;
