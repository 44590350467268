import _defineProperty from "/vercel/path0/node_modules/.pnpm/@babel+runtime@7.15.4/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import Table from '@tiptap/extension-table';
import { styleVariables } from '../../../style';
export var CustomTableCell = TableCell.extend({
  addAttributes: function addAttributes() {
    var _this$parent;

    return _objectSpread(_objectSpread({}, (_this$parent = this.parent) === null || _this$parent === void 0 ? void 0 : _this$parent.call(this)), {}, {
      border: {
        "default": "".concat(styleVariables.borderWidth.small, " solid ").concat(styleVariables.colors.gray3),
        parseHTML: function parseHTML(element) {
          return element.getAttribute('data-border');
        },
        renderHTML: function renderHTML(attributes) {
          return {
            'data-border': attributes.border,
            style: "border: ".concat(attributes.border, ";")
          };
        }
      },
      boxSizing: {
        "default": 'border-box',
        parseHTML: function parseHTML(element) {
          return element.style.boxSizing || 'border-box';
        },
        renderHTML: function renderHTML(attributes) {
          return {
            style: "box-sizing: ".concat(attributes.boxSizing, ";")
          };
        }
      },
      padding: {
        "default": "".concat(styleVariables.padding.small, " ").concat(styleVariables.padding.medium),
        parseHTML: function parseHTML(element) {
          return element.style.padding || "".concat(styleVariables.padding.small, " ").concat(styleVariables.padding.medium);
        },
        renderHTML: function renderHTML(attributes) {
          return {
            style: "padding: ".concat(attributes.padding, ";")
          };
        }
      },
      position: {
        "default": 'relative',
        parseHTML: function parseHTML(element) {
          return element.style.position || 'relative';
        },
        renderHTML: function renderHTML(attributes) {
          return {
            style: "position: ".concat(attributes.position, ";")
          };
        }
      },
      verticalAlign: {
        "default": 'top',
        parseHTML: function parseHTML(element) {
          return element.style.verticalAlign || 'top';
        },
        renderHTML: function renderHTML(attributes) {
          return {
            style: "vertical-align: ".concat(attributes.verticalAlign, ";")
          };
        }
      },
      backgroundColor: {
        "default": null,
        parseHTML: function parseHTML(element) {
          return element.getAttribute('data-background-color');
        },
        renderHTML: function renderHTML(attributes) {
          return {
            'data-background-color': attributes.backgroundColor,
            style: "background-color: ".concat(attributes.backgroundColor || 'transparent', ";")
          };
        }
      }
    });
  }
});
export var CustomTableHeader = TableHeader.extend({
  addAttributes: function addAttributes() {
    var _this$parent2;

    return _objectSpread(_objectSpread({}, (_this$parent2 = this.parent) === null || _this$parent2 === void 0 ? void 0 : _this$parent2.call(this)), {}, {
      backgroundColor: {
        "default": "".concat(styleVariables.colors.gray3),
        parseHTML: function parseHTML(element) {
          return element.style.backgroundColor || "".concat(styleVariables.colors.gray3);
        },
        renderHTML: function renderHTML(attributes) {
          return {
            style: "background-color: ".concat(attributes.backgroundColor, ";")
          };
        }
      },
      border: {
        "default": "".concat(styleVariables.borderWidth.small, " solid ").concat(styleVariables.colors.gray3),
        parseHTML: function parseHTML(element) {
          return element.style.border || "".concat(styleVariables.borderWidth.small, " solid ").concat(styleVariables.colors.gray3);
        },
        renderHTML: function renderHTML(attributes) {
          return {
            style: "border: ".concat(attributes.border, ";")
          };
        }
      },
      fontWeight: {
        "default": 'bold',
        parseHTML: function parseHTML(element) {
          return element.style.fontWeight || 'bold';
        },
        renderHTML: function renderHTML(attributes) {
          return {
            style: "font-weight: ".concat(attributes.fontWeight, ";")
          };
        }
      },
      textAlign: {
        "default": 'left',
        parseHTML: function parseHTML(element) {
          return element.style.textAlign || 'left';
        },
        renderHTML: function renderHTML(attributes) {
          return {
            style: "text-align: ".concat(attributes.textAlign, ";")
          };
        }
      },
      padding: {
        "default": "".concat(styleVariables.padding.small, " ").concat(styleVariables.padding.medium),
        parseHTML: function parseHTML(element) {
          return element.style.padding || "".concat(styleVariables.padding.small, " ").concat(styleVariables.padding.medium);
        },
        renderHTML: function renderHTML(attributes) {
          return {
            style: "padding: ".concat(attributes.padding, ";")
          };
        }
      }
    });
  }
});
export var CustomTable = Table.extend({
  addOptions: function addOptions() {
    var _this$parent3;

    return _objectSpread(_objectSpread({}, (_this$parent3 = this.parent) === null || _this$parent3 === void 0 ? void 0 : _this$parent3.call(this)), {}, {
      resizable: true,
      allowTableNodeSelection: true
    });
  },
  addAttributes: function addAttributes() {
    var _this$parent4;

    return _objectSpread(_objectSpread({}, (_this$parent4 = this.parent) === null || _this$parent4 === void 0 ? void 0 : _this$parent4.call(this)), {}, {
      width: {
        "default": '100%',
        parseHTML: function parseHTML(element) {
          return element.style.width || '100%';
        },
        renderHTML: function renderHTML(attributes) {
          return {
            style: "width: ".concat(attributes.width, ";")
          };
        }
      },
      borderCollapse: {
        "default": 'collapse',
        parseHTML: function parseHTML(element) {
          return element.style.borderCollapse || 'collapse';
        },
        renderHTML: function renderHTML(attributes) {
          return {
            style: "border-collapse: ".concat(attributes.borderCollapse, ";")
          };
        }
      },
      margin: {
        "default": '0',
        parseHTML: function parseHTML(element) {
          return element.style.margin || '0';
        },
        renderHTML: function renderHTML(attributes) {
          return {
            style: "margin: ".concat(attributes.margin, ";")
          };
        }
      },
      overflow: {
        "default": 'hidden',
        parseHTML: function parseHTML(element) {
          return element.style.overflow || 'hidden';
        },
        renderHTML: function renderHTML(attributes) {
          return {
            style: "overflow: ".concat(attributes.overflow, ";")
          };
        }
      },
      tableLayout: {
        "default": 'fixed',
        parseHTML: function parseHTML(element) {
          return element.style.tableLayout || 'fixed';
        },
        renderHTML: function renderHTML(attributes) {
          return {
            style: "table-layout: ".concat(attributes.tableLayout, ";")
          };
        }
      }
    });
  }
});