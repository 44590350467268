import { authenticationRepositoryProvider, userWebRepositoryProvider } from '@module/user';
import { Module } from '@nestjs/common';
import { useCaseProviders } from './usecase-providers';
import { apiProviders } from './api-providers';
import { FileStorageMapper, fileStorageRepositoryProvider } from '@module/file-storage';
import { externalFileStorageRepositoryProvider } from '@module/file-storage/src/data/repositories/external-file-storage.repository';

@Module({
  providers: [
    ...apiProviders,
    authenticationRepositoryProvider,
    userWebRepositoryProvider,
    fileStorageRepositoryProvider,
    externalFileStorageRepositoryProvider,
    FileStorageMapper,
    ...useCaseProviders,
  ],
  exports: [...useCaseProviders],
})
export class AppModule {}
