import { Action, createReducer } from '@lib/plugin-redux-core';
import { GET_USER_BY_ID_USE_CASE, CHANGE_PASSWORD_USE_CASE } from '../../redux/root.action';
import {
  handleChangePasswordUseCase,
  handleCreateProfileFileStorageUseCase,
  handleGetUserByIdUseCase,
  handleUpdateProfileUseCase,
} from './account-setting.handler';
import { initialState } from './account-setting.state';
import { CREATE_PROFILE_FILE_STORAGE_USE_CASE } from '@module/file-storage';
import { UPDATE_PROFILE_USE_CASE } from '@module/user';

export const accountSettingReducer = createReducer(initialState, [
  {
    name: GET_USER_BY_ID_USE_CASE,
    ...handleGetUserByIdUseCase,
  },
  {
    name: UPDATE_PROFILE_USE_CASE,
    ...handleUpdateProfileUseCase,
  },
  {
    name: CHANGE_PASSWORD_USE_CASE,
    ...handleChangePasswordUseCase,
  },
  {
    name: CREATE_PROFILE_FILE_STORAGE_USE_CASE,
    ...handleCreateProfileFileStorageUseCase,
  },
]);
