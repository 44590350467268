import { UpdateUserProfileUseCaseInput, User } from '@module/user';
import { UseCaseError } from '@digibricks/domains';
import { CreatedFileStorage } from '@module/file-storage';

export enum AccountSettingStatus {
  EXECUTING = 1,
  SUCCESS = 2,
  ERROR = 3,
  RESET = 4,
}

export type AccountSettingInitialState = {
  error?: UseCaseError<any>;
  payload: any;
};

export type GetUserInformationUseCaseState = {
  userResponse: User;
  getUserInformationStatus: AccountSettingStatus;
};

export type UpdateProfileUseCaseState = {
  updateProfileStatus: AccountSettingStatus;
  updateProfileInput: UpdateUserProfileUseCaseInput;
};

export type ChangePasswordUseCaseState = {
  updatePasswordStatus: AccountSettingStatus;
};

type CreateProfileFileUseCaseState = {
  createProfileFileResponse: CreatedFileStorage;
  createProfileFileStatus: AccountSettingStatus;
};

export type AccountSettingPageState = AccountSettingInitialState &
  GetUserInformationUseCaseState &
  UpdateProfileUseCaseState &
  ChangePasswordUseCaseState &
  CreateProfileFileUseCaseState;

export const initialState: AccountSettingInitialState = {
  error: null,
  payload: null,
};
