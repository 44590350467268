import {
  SignInUseCase,
  SignUpUseCase,
  SignInByGoogleUseCase,
  IAuthenticationRepository,
  AUTHENTICATION_REPOSITORY,
  ConfirmationUseCase,
  ForgotPasswordUseCase,
  ResetPasswordUseCase,
  SIGN_OUT_FOR_IDENTITY_USE_CASE,
  VERIFY_ACCESS_TOKEN_USE_CASE,
  VerifyAccessTokenUseCase,
  SIGN_IN_BY_GOOGLE_USE_CASE,
  SignOutForIdentityUseCase,
} from '@module/user';

import {
  CONFIRMATION_USE_CASE,
  SIGN_IN_USE_CASE,
  SIGN_UP_USE_CASE,
  FORGOT_PASSWORD_USE_CASE,
  RESET_PASSWORD_USE_CASE,
} from '../../redux/root.action';

export const authUseCaseProviders = [
  {
    provide: SIGN_IN_USE_CASE,
    useFactory: (authenticationRepository: IAuthenticationRepository) => {
      return new SignInUseCase(authenticationRepository);
    },
    inject: [AUTHENTICATION_REPOSITORY],
  },
  {
    provide: SIGN_IN_BY_GOOGLE_USE_CASE,
    useFactory: (authenticationRepository: IAuthenticationRepository) => {
      return new SignInByGoogleUseCase(authenticationRepository);
    },
    inject: [AUTHENTICATION_REPOSITORY],
  },
  {
    provide: SIGN_UP_USE_CASE,
    useFactory: (authenticationRepository: IAuthenticationRepository) => {
      return new SignUpUseCase(authenticationRepository);
    },
    inject: [AUTHENTICATION_REPOSITORY],
  },
  {
    provide: CONFIRMATION_USE_CASE,
    useFactory: (authenticationRepository: IAuthenticationRepository) => {
      return new ConfirmationUseCase(authenticationRepository);
    },
    inject: [AUTHENTICATION_REPOSITORY],
  },
  {
    provide: FORGOT_PASSWORD_USE_CASE,
    useFactory: (authenticationRepository: IAuthenticationRepository) => {
      return new ForgotPasswordUseCase(authenticationRepository);
    },
    inject: [AUTHENTICATION_REPOSITORY],
  },
  {
    provide: RESET_PASSWORD_USE_CASE,
    useFactory: (authenticationRepository: IAuthenticationRepository) => {
      return new ResetPasswordUseCase(authenticationRepository);
    },
    inject: [AUTHENTICATION_REPOSITORY],
  },
  {
    provide: SIGN_OUT_FOR_IDENTITY_USE_CASE,
    useFactory: (authenticationRepository: IAuthenticationRepository) => {
      return new SignOutForIdentityUseCase(authenticationRepository);
    },
    inject: [AUTHENTICATION_REPOSITORY],
  },
  {
    provide: VERIFY_ACCESS_TOKEN_USE_CASE,
    useFactory: (authenticationRepository: IAuthenticationRepository) => {
      return new VerifyAccessTokenUseCase(authenticationRepository);
    },
    inject: [AUTHENTICATION_REPOSITORY],
  },
];
