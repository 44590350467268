import { SignInResponse, User } from '@module/user';
import { UseCaseError } from '@digibricks/domains';

export const enum GoogleCallbackPageStatus {
  'EXECUTING' = 1,
  'SUCCESS' = 2,
  'ERROR' = 3,
  'RESET' = 4,
}

export type SignInByGoogleUseCaseState = {
  signInByGoogleResponse: SignInResponse;
  userResponse: User;
  isLoadingSignInByGoogle: boolean;
  isSignInByGoogleSuccess: boolean;
};

export type GoogleCallbackPageInitialState = {
  error?: UseCaseError<any>;
};

export type UpdateUserInformationUseCase = {
  updateUserInformationStatus: GoogleCallbackPageStatus;
};

export type GoogleCallbackPageState = GoogleCallbackPageInitialState &
  UpdateUserInformationUseCase &
  SignInByGoogleUseCaseState;

export const initialState: GoogleCallbackPageInitialState = {
  error: null,
};
