import { Action } from '@lib/plugin-redux-core';
import { GoogleCallbackPageState, GoogleCallbackPageStatus } from './google-callback.state';
import { SignInResponse, User } from '@module/user';

export const handleUpdateUserInformationUseCase = {
  executing: (state: GoogleCallbackPageState): GoogleCallbackPageState => {
    return {
      ...state,
      updateUserInformationStatus: GoogleCallbackPageStatus.EXECUTING,
    };
  },
  success: (state: GoogleCallbackPageState): GoogleCallbackPageState => {
    return {
      ...state,
      updateUserInformationStatus: GoogleCallbackPageStatus.SUCCESS,
    };
  },
  error: (state: GoogleCallbackPageState, action: Action): GoogleCallbackPageState => {
    const { error } = action;
    return {
      ...state,
      updateUserInformationStatus: GoogleCallbackPageStatus.ERROR,
      error,
    };
  },
};

export const handleSignInByGoogleUseCase = {
  executing: (state: GoogleCallbackPageState, action: Action): GoogleCallbackPageState => {
    return { ...state, isLoadingSignInByGoogle: true, isSignInByGoogleSuccess: false };
  },
  success: (state: GoogleCallbackPageState, action: Action) => {
    const { payload } = action;
    const signInByGoogleResponse = payload as SignInResponse;
    return {
      ...state,
      signInByGoogleResponse,
      userResponse: signInByGoogleResponse?.profile,
      isLoadingSignInByGoogle: false,
      isSignInByGoogleSuccess: true,
    };
  },
  error: (state: GoogleCallbackPageState, action: Action): GoogleCallbackPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      isLoadingSignInByGoogle: false,
      isSignInByGoogleSuccess: false,
    };
  },
};
